<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('PRODUCTS_CATEGORY.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'productsCategoriesIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group id="input-group-1" :label="`${$t('FORM.NAME')}`" label-for="input-1" label-cols-md="2"
                          label-align-md="right"
            >
              <InputForm
                id="input-1"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                trim
                @input="clearServerError('name')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-2" :label="`${$t('FORM.PARENT')}`" label-for="select-1" label-cols-md="2"
                          label-align-md="right"
            >
              <alioth-select v-model="$v.form.parent.$model" repository="stock/product-categories"
                             :invalid="validateState('parent') === false"
                             @input="clearServerError('parent')"
              />
              <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('parent') === false}">
                <template v-if="serverErrors.parent">{{ serverErrors.parent[0] }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/productCategoriesRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../../../content/Card'
import InputForm from '../../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../../mixins/serverVuelidate'
import AliothSelect from '../../../../../../components/forms-items/alioth-select'

export default {
  name: 'ProductsCategoriesEdit',
  components: {
    AliothSelect,
    InputForm,
    KTCard,
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      form: {
        name: '',
        parent: null,
      },
      searchParent: '',
      categoryCopy: {},
      validations: {
        form: {
          name: { required },
          parent: {},
        },
      },
    }
  },
  computed: {
    paramsCategory() {
      return {
        filter: {
          uuid: {
            operator: 'neq',
            value: this.categoryCopy.uuid,
          },
        },
      }
    },
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PRODUCTS_CATEGORY.TITLE_EDIT') }])
  },
  methods: {
    fetchData() {
      Repo.get(this.$route.params.id)
        .then(({ data }) => {
          this.setItem(data.payload)
          this.categoryCopy = data.payload
        })
    },
    setItem(item) {
      Object.keys(this.form)
        .forEach((propName) => {
          if (
            this.form.hasOwnProperty(propName)
                && item.hasOwnProperty(propName)
          ) {
            if (item[propName] !== null && item[propName] !== undefined) {
              this.form[propName] = item[propName]
            }
          }
        })
    },
    validateState(name) {
      const {
        $dirty,
        $error,
      } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true

      const obj = { ...this.form }
      obj.parent = obj.parent ? obj.parent.uuid : obj.parent

      Repo.patch(this.$route.params.id, obj)
        .then(() => {
          this.$bvToast.toast(this.$t('TOAST.EDITED'), {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true,
          })
          setTimeout(() => this.$router.push({ name: 'productsCategoriesIndex' }), TIMEOUT_REDIRECT)
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
        })
    },
  },
}
</script>

<style scoped>

</style>
